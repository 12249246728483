html {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}

.ais-GeoSearch {
	height: 100%;
	width: 50%;
}

.ais-GeoSearch-map {
	height: 100%;
}

#root {
	height: 100%;
}

.advanced-filters {
	font-size: .875rem;
    height: 2.5rem;
    line-height: 1.25rem;
}

.advanced-filters-icon {
	background-image: url(./assets/settings.svg);
	background-size: contain;
    content: "";
    height: 1rem;
    left: 30px;
    margin-top: -0.5rem;
    position: absolute;
    top: 27px;
    width: 1rem;
}

.ais-Hits-item div {
	padding: 1.5rem;
}

.ais-Hits-item {
	padding: 0px;
}

.ais-ClearRefinements-button-enabled {
    background-image: linear-gradient(-180deg, #fff, #fcfcfd);
    box-shadow: 0 1px 0 0 rgb(35 38 59 / 5%);
    color: #23263b;
    cursor: pointer;
}

.ais-GeoSearch {
    width: 100%;
}

.filter-card {
    box-shadow: 0 0 0 1px rgb(35 38 59 / 5%), 0 1px 3px 0 rgb(35 38 59 / 15%);
    padding: 1rem;
    background-color: white;
}

.ais-SearchBox-form-filter {
	background-size: contain;
    content: "";
    height: 1rem;
    margin-top: 0.25rem;
    width: 1rem;
}

.left-arrow-filter {
	background-size: contain;
    content: "";
    height: 0.75rem;
    margin-top: 0.25rem;
}

.custom-map-pin {
    cursor: pointer;
	background-size: contain;
    content: "";
    height: 2.75rem;
}

.ais-SearchBox-input {
    padding-right: 2.5rem;
}

.padding-horizontal-1-rem {
    padding-left: 1rem;
    padding-right: 1rem;
}

.padding-vertical-half-rem {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.margin-horizontal-1-rem {
    margin-left: 1rem;
    margin-right: 1rem;
}

.margin-horizontal-half-rem {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.padding-vertical-half-rem {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.ais-SearchBox-reset {
    right: 1.5rem;
}

.ais-advanced-filter-position {
    right: 110px;
}

.height-64px {
    height: 64px;
}

.height-48px {
    height: 48px;
}

.parent-height-exclude-112px {
    height: calc( 100% - 112px );
}

.parent-height-exclude-64px {
    height: calc( 100% - 64px );
}

.background-color-primary {
    background: #0249D6;
}

.ais-SearchBox-form, .ais-SearchBox {
    border-radius: 3px;
}

.chip {
    border-radius: 7px;
    background-color: #E4E4E4;
    padding: 6px 8px;
    cursor: pointer;
}

.table {
    border-collapse: separate;
    width: 100%;
    overflow: auto;
    border-spacing: 0;
  }
  
  .table td, .table th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .table tr:nth-child(even){background-color: #f2f2f2;}
  
  .table tr:hover {background-color: #ddd;}
  
  .table th {
    border: 1px solid #ddd;
    position: sticky;
    top: 0px;
    background-color: white;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;  
    background: #d3d3d3;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%; 
    background: #0249D6;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #0249D6;
    cursor: pointer;
  }

  .switch-button {
    background: rgb(21, 95, 255, 0.56);
    border-radius: 30px;
    overflow: hidden;
    width: 188px;
    height: 24px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    position: relative;
    padding-right: 94px;
    position: relative;
}
.switch-button:before {
    font-size: 14px;
    content: "Table";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
}
.switch-button-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}
.switch-button-checkbox:checked + .switch-button-label:before {
    transform: translateX(94px);
    transition: transform 300ms linear;
}
.switch-button-checkbox + .switch-button-label {
    position: relative;
    display: block;
    user-select: none;
    pointer-events: none;
    height: 100%;
}
.switch-button-checkbox + .switch-button-label:before {
    content: "";
    background: #fff;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 30px;
    transform: translateX(0);
    transition: transform 300ms;
}
.switch-button-checkbox + .switch-button-label .switch-button-label-span {
    position: relative;
    font-size: 14px;
}

@keyframes shadows {
    0% {
    color: unset; }
    50% { background-color: #0249D6; 
        color: white;}
    100% {  
    color: unset;}
   }